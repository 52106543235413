@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  background-color: black;
}
/* Switch */
.ant-switch-handle::before {
  background-color: aqua !important;
}
.ant-switch-inner {
  background-color: black !important;
}

.border-gradient {
  border-color: linear-gradient(#f5f7fa00, #00f9a9);
}
.bg_gradient {
  box-shadow: 0px 4px 4px 0px #090d141f;

  box-shadow: -2px -2px 4px 0px #e0e0ff05;

  box-shadow: 0px 1px 2px 0px #090d143d;
}
.tradingview_bce7f-wrapper {
}
.btn-gradient {
  border-style: solid;
  border-image: linear-gradient(to right, #4f9dff, #67ffd1) 1;
}
.btn-gradient1 {
  border-image: linear-gradient(to right, #4f9dff, #67ffd1) 1;
}

/* bar-chart */
.canvasjs-chart-canvas {
  background-color: rgba(0, 0, 0, 0.3);
}
.canvasjs-chart-toolbar {
  display: none !important;
}
.canvasjs-chart-credit {
  display: none;
}
::-webkit-scrollbar {
  display: none;
}

.buy-button {
  border: none;
  background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, #00f9a9 100%);
  border-radius: 1rem;
  padding: 1px;
  box-shadow: 0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24), 0px 8px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(56px);
}
.buy-button-inner {
  background: linear-gradient(135deg, #222529 0%, #15181c 100%);
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}
.buy-button-inner:hover {
  background: #000;
  cursor: pointer;
}
.button-text {
  font-size: 1rem;
  background: linear-gradient(334deg, #0ff 0%, #00f9a9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* tab-select */

.social_web_login {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #0f1012;
  padding: 8px, 24px, 8px, 24px;
  border-top-left-radius: 13px 15px;
}
.react-tabs__tab--selected.social_web_login {
  background: #1f2122;
  border: none;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 1px, 0px;
  padding: 8px, 24px, 8px, 24px;
}

.social_web_login_1 {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #0f1012;
  padding: 8px, 24px, 8px, 24px;
  border-top-right-radius: 13px 15px;
}
.react-tabs__tab--selected.social_web_login_1 {
  background: #1f2122;
  border: none;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 1px, 0px;
  padding: 8px, 24px, 8px, 24px;
}

.react-tabs__tab--selected.social_web_login_2 {
  background: #1f2122;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 2px, 0px;
  padding: 8px, 24px, 8px, 24px;
  border-top-left-radius: 13px 15px !important;
}
.react-tabs__tab--selected.social_web_login_3 {
  background: #1f2122;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  padding: 8px, 24px, 8px, 24px;
}
.react-tabs__tab--selected.social_web_login_4 {
  background: #1f2122;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  padding: 8px, 24px, 8px, 24px;
  border-top-right-radius: 13px 15px !important;
}

/* bar-chart */
@media screen and (max-width: 426px) {
  .recharts-wrapper {
    width: 21rem !important;
  }
}
